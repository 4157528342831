// check default language
export function getRedirectLanguage(setLang) {
  if (typeof navigator === `undefined`) {
    return ""
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0]
  if (!lang) return ""

  switch (lang) {
    case "es":
      setLang("es")
      return "es"
    default:
      return ""
  }
}
