import React from "react"
import PropTypes from "prop-types"

// react icons
import { ImLinkedin } from "react-icons/im"

const Social = () => {
  return (
    <section className="h-24 bg-gray-200 text-3xl text-darkBlue flex items-center justify-between">
      <div className="mx-auto">
        <a
          href="https://www.linkedin.com/company/energy-efficient-engineering/about/"
          target="_blank"
          rel="noreferrer"
        >
          <ImLinkedin />
        </a>
      </div>
    </section>
  )
}

Social.propTypes = {
  lang: PropTypes.object,
}

export default Social
