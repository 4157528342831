import React from "react"
import Proptypes from "prop-types"

// react-hook-form
import { useForm } from "react-hook-form"

// axios helpers
import { emailPost } from "../../utils/axiosRequests"

export default function Form({ lang }) {
  const { register, handleSubmit, reset } = useForm()

  const onSubmit = async data => {
    const res = await emailPost(data)
    if (res.status === 200) {
      reset(res)
      alert("form submited, thank you for your interest")
    } else {
      alert(`error --> ${res.message}`)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="" className="m-0 max-w-500">
      <div className="flex flex-col font-body text-sm space-y-4 bg-darkGreen bg-opacity-0 p-8 py-14 border">
        <div className="text-center">
          <h3 className="m-0 text-xl tracking-wide font-body font-light">
            {lang.title2}
          </h3>
          <p className="m-0 font-light my-2 text-xs text-gray-500">
            {lang.subTitle2}
          </p>
        </div>
        <div className="flex space-x-2 w-full">
          <input
            className="w-full text-black"
            name="name"
            ref={register}
            placeholder={lang.name}
          />
          <input
            className="w-full text-black"
            type="email"
            name="email"
            ref={register}
            placeholder={lang.email}
          />
        </div>
        <div className="flex space-x-2 w-full">
          <input
            className="w-full text-black"
            name="company"
            ref={register}
            placeholder={lang.companyName}
          />
          <input
            className="w-full text-black"
            name="industry"
            ref={register}
            placeholder={lang.industry}
          />
        </div>
        <textarea
          className="bg-white bg-opacity-0 px-4 py-2 border h-24 resize-none w-full"
          name="body"
          ref={register}
          placeholder={lang.textArea}
        />
        <input
          className="self-start border-none text-sm uppercase cursor-pointer hover:shadow-none duration-500 px-8 py-2 bg-blue-500 text-white font-body font-bold tracking-widest"
          type="submit"
        />
      </div>
    </form>
  )
}

Form.propTypes = {
  lang: Proptypes.object,
}
