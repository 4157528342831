import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

// components
import Form from "../form/contact"

const Hero = ({ fluid, lang }) => {
  return (
    <section className="w-full h-700 relative desktop:mt-32 mobile:mt-24">
      <div
        className="absolute max-w-1024 mx-auto left-0 right-0 desktop:px-2x h-700"
        style={{ zIndex: "-10" }}
      >
        <Img className="w-full h-full" fluid={fluid} loading="eager" />
      </div>
      <div className="mobile:mt-24 container max-w-1024 desktop:px-2x h-700 flex items-center">
        <div className="w-full h-full flex items-center justify-between mobile:flex-col mobile:justify-around mobile:items-start px-6">
          <div className="text-white font-body">
            <h1 className="font-heading capitalize desktop:text-5xl desktop:w-500 leading-tight mb-4">
              {lang.hero.headerText}
            </h1>
            <h3 className="ml-2 text-light text-xs desktop:w-350 mobile:max-w-500 mobile:mb-4 m-0 tracking-widest">
              {lang.hero.subText}
            </h3>
          </div>
          <Form lang={lang.contact} />
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  fluid: PropTypes.object,
  lang: PropTypes.object,
}

export default Hero
