import React from "react"
import PropTypes from "prop-types"

const Mission = ({ lang }) => {
  return (
    <section className="w-full max-w-1024 mx-auto pt-12 text-center mb-14">
      <p className="text-darkBlue font-body uppercase text-xs font-bold tracking-widest mb-4">
        {lang.title}
      </p>
      <p className="max-w-750 mx-auto text-xl font-body font-thin text-gray-500">
        {lang.text}
      </p>
    </section>
  )
}

Mission.propTypes = {
  lang: PropTypes.object,
}

export default Mission
