// Axios
import axios from "axios"

// email server post
export async function emailPost(data) {
  const res = await axios.post(
    "https://eee-mail-server.herokuapp.com/api/contact",
    data
  )
  return res
}
