import React from "react"
import Proptypes from "prop-types"

// components
import Form from "../form/contactBelow"

export default function Contact({ lang }) {
  return (
    <section className="mb-16">
      <div className="mx-auto flex flex-wrap justify-around max-w-1024 px-2x desktop:pl-10 mobile:px-2x pt-16">
        <div className="tracking-wide text-sm max-w-500 mb-10">
          <h1 className="font-body font-thin text-xl mb-6">{lang.title}</h1>
          <hr
            className="w-10 border border-blue-500 mb-4"
            style={{ borderTop: "1px solid #1973CE" }}
          />
          <p className="font-body text-xs text-gray-500 capitalize tracking-widest">
            {lang.tag}
          </p>
          <ul className="font-body flex flex-col justify-between m-0 max-w-450">
            <li className="flex flex-col mr-8">
              <p className="text-blue-500 text-xs">{lang.address}</p>
              <span className="text-gray-400">Miami Beach, Florida 33140</span>
            </li>
            <li className="flex flex-col">
              <p className="text-blue-500 text-xs">{lang.callTitle}</p>
              <a href="tel:+17864684023" className="text-gray-400">
                786 - 468 - 4023
              </a>
              <a
                href="mailto:marcoburgos@energy-ee.com"
                className="text-gray-400"
              >
                marcoburgos@energy-ee.com
              </a>
            </li>
          </ul>

          {/* interactive google map PERFORMACE ISSUES */}
          {/* <div
            className="gmap_canvas w-full mt-10"
            style={{
              overflow: "hidden",
              background: "none",
              height: "250px",
            }}
          >
            <iframe
              title="Location Map"
              className="w-full h-full"
              // width="400"
              // height="250"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Miami%20Beach,%20Florida%2033140&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div> */}
        </div>
        <Form lang={lang} />
      </div>
    </section>
  )
}

Contact.propTypes = {
  lang: Proptypes.object,
}
