import React from "react"
import Proptypes from "prop-types"

// react-hook-form
import { useForm } from "react-hook-form"

// axios helpers
import { emailPost } from "../../utils/axiosRequests"

export default function Form({ lang }) {
  const { register, handleSubmit, reset } = useForm()

  const onSubmit = async data => {
    const res = await emailPost(data)
    if (res.status === 200) {
      reset(res)
      alert("form submited, thank you for your interest")
    } else {
      alert(`error --> ${res.message}`)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="m-0 desktop:w-80 mobile:max-w-400 flex flex-col font-body text-sm space-y-4 bg-darkGreen bg-opacity-90 p-8"
    >
      <div className="text-center">
        <p className="m-0 text-xs text-lightGreen lowercase tracking-wide">
          {lang.title}
        </p>
        <h3 className="m-0 text-white font-light my-2">{lang.subTitle}</h3>
      </div>
      <div className="mobile:flex mobile:space-x-4 mobile:w-full desktop:space-y-4">
        <input
          className="inputL w-full"
          name="name"
          ref={register}
          placeholder={lang.name}
        />
        <input
          className="inputL w-full"
          type="email"
          name="email"
          ref={register}
          placeholder={lang.email}
        />
      </div>
      <div className="mobile:flex mobile:space-x-4 mobile:w-full desktop:space-y-4">
        <input
          className="inputL w-full"
          name="company"
          ref={register}
          placeholder={lang.companyName}
        />
        <input
          className="inputL w-full"
          name="industry"
          ref={register}
          placeholder={lang.industry}
        />
      </div>
      <textarea
        className="textareaL bg-white bg-opacity-0 px-4 py-2 text-white border h-24 resize-none"
        name="body"
        ref={register}
        placeholder={lang.textArea}
      />
      <input
        className="self-start border-none text-sm uppercase cursor-pointer hover:shadow-none duration-500 px-8 py-2 bg-lightGreen text-white font-body font-bold tracking-wide"
        type="submit"
      />
    </form>
  )
}

Form.propTypes = {
  lang: Proptypes.object,
}
