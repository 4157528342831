import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const Stats = ({ lang, fluidBelow }) => {
  return (
    <section className="w-full h-600 relative flex items-center justify-center mt-10">
      <div
        className="absolute max-w-1024 mx-auto left-0 right-0 desktop:px-2x h-600"
        style={{ zIndex: "-10" }}
      >
        <Img className="w-full h-full" fluid={fluidBelow} loading="eager" />
      </div>
      <div className="min-h-350 bg-white flex flex-wrap justify-between py-10 px-6">
        <h1 className="font-body text-2xl uppercase mr-16">
          Energy <br />
          Efficient <br />
          <span className="text-blue-500">Engineering</span>
        </h1>
        <div className="text-gray-400 font-body text-sm w-550 mobile:w-full">
          <p>{lang.body1}</p>
          <p>{lang.body2}</p>
          <div className="flex justify-start flex-wrap">
            <div className="flex flex-col mr-6">
              <p className="text-blue-500 text-xl font-body font-bold mb-1 font-heading">
                15+
              </p>
              <p className="text-xs lowercase tracking-wide">{lang.stat1}</p>
            </div>
            <div className="flex flex-col mr-6">
              <p className="text-blue-500 text-xl font-body font-bold mb-1 font-heading">
                >12.5M
              </p>
              <p className="text-xs lowercase tracking-wide">{lang.stat2}</p>
            </div>
            {/* statistic for emissions tracking */}
            {/* <div className="flex flex-col mr-6">
              <p className="text-blue-500 text-xl font-body font-bold mb-1 font-heading">
                $20B+
              </p>
              <p className="text-xs lowercase tracking-wide">{lang.stat3}</p>
            </div> */}
            <div className="flex flex-col ">
              <p className="text-blue-500 text-xl font-body font-bold mb-1 font-heading">
                7
              </p>
              <p className="text-xs lowercase tracking-wide">{lang.stat4}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Stats.propTypes = {
  fluidBelow: PropTypes.object,
  lang: PropTypes.object,
}

export default Stats
